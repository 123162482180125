<template>
  <PageLoader min-height="100vh" v-if="loading"></PageLoader>
  <v-card v-else class="rounded-10" outlined min-height="500">
    <v-card-title>
      <h2 class="primary--text heading font-weight-bold">Affiliation</h2>
      <v-spacer v-if="view_user_is_client && can_add_affiliaton" />
      <v-btn
        depressed
        color="primary"
        dark
        @click="createCompany"
        class="text-none"
        v-if="view_user_is_client && can_add_affiliaton"
      >
        <v-icon color="white" left>mdi-plus</v-icon>
        Add New Affiliation
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col
          class="col-md-6 col-sm-12"
          v-for="client_company in companies"
          :key="client_company.id"
        >
          <v-hover>
            <template v-slot:default="{ hover }">
              <v-card color="primary" dark class="mb-1">
                <v-card-text>
                  <div class="d-md-flex flex-no-wrap justify-space-between">
                    <div class="text-sm-center text-md-left">
                      <v-card-title class="headline font-weight-bold">
                        {{ client_company.name | ucwords }}
                      </v-card-title>
                      <v-card-subtitle
                        class="py-1"
                        v-if="client_company.short_description"
                      >
                        Slogan :
                        {{ client_company.short_description }}
                      </v-card-subtitle>
                      <v-card-subtitle
                        class="py-1"
                        v-if="client_company.long_description"
                      >
                        Long Description: {{ client_company.long_description }}
                      </v-card-subtitle>
                      <v-card-subtitle
                        class="py-1"
                        v-if="client_company.tag_line"
                      >
                        Tag line: {{ client_company.tag_line }}
                      </v-card-subtitle>
                      <v-card-subtitle class="py-1" v-if="client_company.email">
                        Email : {{ client_company.email }}
                      </v-card-subtitle>
                      <v-card-subtitle
                        class="py-1"
                        v-if="client_company.domain"
                      >
                        Website :
                        <a
                          class="light--text"
                          :href="normalizeDomain(client_company.domain)"
                          target="_blank"
                        >
                          {{ client_company.domain }}
                        </a>
                      </v-card-subtitle>
                    </div>
                    <v-avatar class="ma-md-3 mx-auto" :size="200" tile>
                      <v-img
                        contain
                        class="rounded-10 mx-auto"
                        :src="client_company.company_logo"
                      ></v-img>
                    </v-avatar>
                  </div>
                </v-card-text>
                <v-fade-transition>
                  <v-overlay
                    v-if="hover && can_update_company(client_company)"
                    absolute
                    color="#036358"
                  >
                    <v-btn
                      class="text-none primary--text"
                      color="white"
                      depressed
                      @click.stop="editCompany(client_company)"
                    >
                      <v-icon left>mdi-circle-edit-outline</v-icon> Edit
                      Affiliation
                    </v-btn>
                  </v-overlay>
                </v-fade-transition>
              </v-card>
            </template>
          </v-hover>
        </v-col>
      </v-row>
    </v-card-text>

    <AffiliationDialog
      :company="activeCompany"
      v-model="affiliation_dialog"
      :uploaded-file="uploadedFile"
      @add-image="image_uploader_dialog = true"
      @save="handleSaveCompany"
      @update="handleUpdateCompany"
    ></AffiliationDialog>

    <ImageCropper
      v-model="image_uploader_dialog"
      @file="handleFileUpload"
      value-format="attachment"
    ></ImageCropper>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AffiliationDialog from '../components/AffiliationDialog.vue'
import ImageCropper from '@/common/Dialogs/ImageCropper.vue'
export default {
  components: { AffiliationDialog, ImageCropper },
  data: () => ({
    affiliation_dialog: false,
    image_uploader_dialog: false,
    activeCompany: null,
    uploadedFile: null
  }),
  computed: {
    ...mapGetters(['user']),
    ...mapGetters('memberProfile', [
      'view_user',
      'view_user_roles',
      'loading',
      'company',
      'companies'
    ]),
    view_user_is_client() {
      return this.$store.getters['memberProfile/view_user_has_role']('client')
    },
    can_add_affiliaton() {
      if (this.user.is_owner || this.user.is_super_admin) return true
      if (this.user.id === this.view_user.id) return true
      if (this.user.is_admin && this.view_user.is_admin) return false
      if (this.user.is_admin) return true
      if (
        this.user.is_manager &&
        (this.view_user.is_member || this.view_user.is_client)
      )
        return true
      return false
    }
  },

  methods: {
    normalizeDomain(domain) {
      return domain.includes('http') ? domain : `https://${domain}`
    },
    handleFileUpload(attachment) {
      this.uploadedFile = attachment
      this.image_uploader_dialog = false
    },
    can_update_company(company) {
      if (this.user.is_client && !company.is_private) return false
      if (this.user.is_owner || this.user.is_super_admin) return true
      if (this.user.id === this.view_user.id) return true
      if (this.user.is_admin && this.view_user.is_admin) return false
      if (this.user.is_admin) return true
      if (
        this.user.is_manager &&
        (this.view_user.is_member || this.view_user.is_client)
      )
        return true
      return false
    },
    createCompany() {
      this.activeCompany = null
      this.affiliation_dialog = true
    },
    editCompany(company) {
      this.activeCompany = company
      this.affiliation_dialog = true
    },
    handleSaveCompany(payload) {
      payload.client_id = this.view_user.id
      this.$store.dispatch('memberProfile/createCompany', {
        payload: payload,
        cb: (data) => {
          this.affiliation_dialog = false
          this.appSnackbar('Affiliation created.')
        }
      })
    },
    handleUpdateCompany(payload) {
      this.$store.dispatch('memberProfile/updateCompany', {
        companyId: this.activeCompany.id,
        payload: payload,
        cb: (data) => {
          this.affiliation_dialog = false
          this.activeCompany = null
          this.appSnackbar('Affiliation updated.')
        }
      })
    }
  }
}
</script>

<style></style>

<template>
  <CustomDialog
    title="Update Affiliation Info"
    button1-text="Cancel"
    :button2-text="buttonText"
    :open.sync="open"
    @button2="save"
    :main-btn-disabled="!valid"
    :max-width="800"
  >
    <template v-slot:content>
      <v-card flat>
        <v-card-text>
          <v-form v-model="valid">
            <v-row dense justify="center" align="center">
              <v-col :cols="mdAndUp ? 8 : 12">
                <v-row dense>
                  <v-col class="mb-1" cols="12">
                    <label class="primary--text subtitle-2"> Name *</label>
                    <v-text-field
                      outlined
                      dense
                      :rules="requiredRules"
                      hide-details="auto"
                      v-model="comp.name"
                    />
                  </v-col>
                  <v-col class="mb-1" cols="12">
                    <label class="primary--text subtitle-2"> Website</label>
                    <v-text-field
                      dense
                      outlined
                      hide-details="auto"
                      v-model="comp.domain"
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col :cols="mdAndUp ? 4 : 12" class="text-center">
                <v-badge bordered color="primary" icon="mdi-camera" overlap>
                  <v-avatar
                    id="avatar-uploader"
                    size="140"
                    class="mx-auto rounded-10"
                    @click="uploadImage"
                  >
                    <v-img
                      :lazy-src="settings.loader"
                      contain
                      :src="file.url_inline"
                      v-if="file"
                    ></v-img>
                    <v-img
                      contain
                      :lazy-src="settings.loader"
                      :src="company.company_logo"
                      v-else-if="company"
                    ></v-img>
                    <v-icon color="primary" large v-else>mdi-camera</v-icon>
                  </v-avatar>
                </v-badge>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col class="mb-1" cols="12">
                <label class="primary--text subtitle-2">Slogan</label>
                <v-text-field
                  outlined
                  dense
                  hide-details="auto"
                  v-model="comp.short_description"
                />
              </v-col>
              <v-col class="mb-1" cols="12">
                <label class="primary--text subtitle-2">Long Description</label>
                <v-textarea
                  :rows="2"
                  outlined
                  dense
                  auto-grow
                  hide-details="auto"
                  v-model="comp.long_description"
                />
              </v-col>
              <v-col class="mb-1" :cols="mdAndUp ? 6 : 12">
                <label class="primary--text subtitle-2"> Email</label>
                <v-text-field
                  outlined
                  type="email"
                  dense
                  :rules="emailRules"
                  hide-details="auto"
                  v-model="comp.email"
                />
              </v-col>
              <v-col class="mb-1" :cols="mdAndUp ? 6 : 12">
                <label class="primary--text subtitle-2">Address</label>
                <v-text-field
                  outlined
                  dense
                  hide-details="auto"
                  v-model="comp.address"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </template>
    <template v-slot:left-buttons>
      <span class="required red--text">*</span> Required Fields
    </template>
  </CustomDialog>
</template>

<script>
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'
import _ from 'lodash'

export default {
  name: 'UpdateCompanyModal',
  components: {
    CustomDialog
  },
  props: {
    value: { type: Boolean, default: false },
    company: Object,
    uploadedFile: Object
  },
  data: () => ({
    open: false,
    valid: false,
    file: null,
    comp: {
      name: null,
      short_description: null,
      long_description: null,
      domain: null,
      email: null,
      address: null,
      company_logo: null,
      logo_uuid: null,
      tag_line: null
    },
    reset: {
      name: null,
      short_description: null,
      long_description: null,
      domain: null,
      email: null,
      tag_line: null,
      address: null,
      company_logo: null,
      logo_uuid: null
    },
    btnloading: false
  }),
  computed: {
    isEdit() {
      return this.company ? true : false
    },
    buttonText() {
      return this.isEdit ? 'Update' : 'Save'
    }
  },
  watch: {
    uploadedFile(val) {
      this.file = val
      if (val) {
        this.comp.company_logo = val.url_inline
        this.comp.logo_uuid = val.uuid
      }
    },
    value(val) {
      this.open = val
      if (val) {
        this.file = null
      }
    },
    open(val) {
      this.$emit('input', val)
    },
    company: {
      handler(val) {
        if (val) this.mapItem(_.cloneDeep(val))
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    uploadImage() {
      this.$emit('add-image', this.company)
    },
    save() {
      if (!this.comp.name) {
        this.appSnackbar('Name is required!', 'error')
        return
      }
      this.$emit(this.isEdit ? 'update' : 'save', this.comp)
      this.$nextTick(this.resetAll())
    },
    resetAll() {
      this.comp = _.cloneDeep(this.reset)
      this.file = null
    },
    closeDialog() {
      this.open = false
    },
    mapItem(item) {
      this.comp = {
        name: item.name,
        short_description: item.short_description || null,
        long_description: item.long_description || null,
        domain: item.domain || null,
        email: item.email || null,
        tag_line: item.tag_line || null,
        address: item.address || null,
        company_logo: item.company_logo || null,
        logo_uuid: item.logo ? item.logo.uuid : null
      }
    }
  }
}
</script>

<style scoped lang="scss">
#avatar-uploader {
  border: 1px solid $blue;
  border-radius: 10px;
  cursor: pointer;
}
</style>
